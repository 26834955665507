import { Link } from "react-router-dom";
import setupClaim from '../assets/images/setup_claim.png'
import manageWallets from '../assets/images/manage_wallets.png'
import SetupClaim from "../components/SetupClaim";
import ManageWallets from "../components/ManageWallets";
import { useState } from "react";
const EditContract = () => {
    const [setupComponent, setSetupComponent] = useState(true)
    console.log(setupComponent)
    return (
        <div className="container">
            <div className="manage-contract">
                <aside className="manage-sidebar">
                    <ul>
                        <li
                            className={setupComponent ? 'active' : ""}
                            onClick={() => {
                                if(!setupComponent) setSetupComponent(!setupComponent)
                            }}
                        >
                            <img src={setupClaim} alt="" />
                            <span> Set up claim</span>
                        </li>
                        <li 
                            className={!setupComponent ? 'active' : ""}
                            onClick={() =>{
                                if(setupComponent) setSetupComponent(!setupComponent)
                            }}
                        >
                            <img src={manageWallets} alt="" />
                            <span>Manage wallets</span>
                        </li>
                    </ul>
                </aside>
                <div className="manage-contract-content">
                    {
                        setupComponent
                        ? 
                        <SetupClaim />
                        :
                        <ManageWallets />

                    }
                </div>
            </div>

        </div>
    )
}

export default EditContract;