import claim_btn from '../assets/images/claim_btn.png'
import connect_left_bg from '../assets/images/connect_left_bg.png'
import connect_right_bg from '../assets/images/connect_right_bg.png'

// data for contract and web3
import Web3 from 'web3'
import claims from '../abi/claims.json'
import tokens from '../abi/tokens.json'
// redux data
import { changeAlertData, changeAddress } from '../store/slices/alertSlice'
// hooks
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
//components
import Alert from '../components/Alert'
import Loading from '../components/Loading'
//functions
import { getAllocated, getUnlocked, getClaimable, getClaimed, getIntervalInSeconds, getTimeEnd, getTimeStart, getLabel, getContractBalance } from '../helpers/helpers'
const web33 = new Web3(window.ethereum)

const ConnectedPage = () => {
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    // data from smartContract
    const [allocated, setAllocated] = useState(null)
    const [unlocked, setUnlocked] = useState(null)
    const [claimable, setClaimable] = useState(null)
    const [claimed, setClaimed] = useState(null)
    const [seconds, setSeconds] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [label, setLabel] = useState(null)
    const [tokenAddress, setTokenAddress] = useState(null)
    const [symbol, setSymbol] = useState(null)
    const [contractBalance,setContractBalance] = useState(null)


    // features for create smartContract and get data
    const [web3, setWeb3] = useState(null)
    const [contract, setContract] = useState(null)
    const [tokenContract, setTokenContract] = useState(null)
    const { userAccount, contractAddress, alertInfo } = useSelector(state => state.alert)
    const dispatch = useDispatch()
    // used text if there are no data
    const data_text = '0'
    // get data from smartContract
    const getData = async () => {
        if (web3 && contract && userAccount) {
            //get allocated data
            let allocated = +(await getAllocated(web3, contract, userAccount))
            allocated = Math.round(allocated * 1000000) / 1000000
            setAllocated(allocated)
            //get unlocked data
            let unlocked = +(await getUnlocked(web3, contract, userAccount))
            unlocked = Math.round(unlocked * 1000000) / 1000000
            setUnlocked(unlocked)
            //get claimable data
            let claimable = +(await getClaimable(web3, contract, userAccount))
            claimable = Math.round(claimable * 1000000) / 1000000
            setClaimable(claimable)
            // get claimed data
            let claimed = +(await getClaimed(web3, contract, userAccount))
            claimed = Math.round(claimed * 1000000) / 1000000
            setClaimed(claimed)
            // get token address
            setTokenAddress(await contract?.methods.token().call({ from: userAccount }))
        } else {
            setWeb3(web33)
            setContract(new web33.eth.Contract(claims, contractAddress))
        }
    }
    const getDate = async (contract) => {
        formatDuration(await getIntervalInSeconds(contract))
        // get time end
        setEndTime(await getTimeEnd(contract))
        // get time start
        setStartTime(await getTimeStart(contract))
        //get label
        setLabel(await getLabel(contract))
    }
    // format date
    function formatDuration(seconds_) {
        const seconds = Math.floor(seconds_);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        const remainingDays = days - months * 30;
        const remainingHours = hours - days * 24;
        const remainingMinutes = minutes - hours * 60;
        const remainingSeconds = seconds - minutes * 60;

        const parts = [];
        if (months > 0) {
            parts.push(`${months} mon.`);
        }
        if (remainingDays > 0) {
            parts.push(`${remainingDays} d.`);
        }
        if (remainingHours > 0) {
            parts.push(`${remainingHours} h.`);
        }
        if (remainingMinutes > 0) {
            parts.push(`${remainingMinutes} min.`);
        }
        if (remainingSeconds > 0) {
            parts.push(`${remainingSeconds} sec.`);
        }

        setSeconds(parts.join(' '))
    }
    // get Web3
    useEffect(() => {
        let contractAddress = location.pathname.split('/')
        contractAddress = contractAddress[contractAddress.length - 1]
        setWeb3(web33)
        setContract(new web33.eth.Contract(claims, contractAddress))
        dispatch(
              changeAddress(contractAddress)
        )
    }, [])
    // get Date
    useEffect(() => {
        if (contract) {
            getDate(contract)
        }
    }, [contract])
    // token address
    useEffect(() => {
        if (tokenAddress) {
            setTokenContract(new web3.eth.Contract(tokens, tokenAddress))
        }
    }, [tokenAddress])
    useEffect(() => {
        const getSymbol = async () => {
            setSymbol(await tokenContract.methods.symbol().call())
            setContractBalance(+(await getContractBalance(web3,tokenContract,contractAddress)))
        }
        if (tokenContract) {
            getSymbol()
        }
    }, [tokenContract])
    // activate function to get data from smartContract
    useEffect(() => {
        if (userAccount && web3) {
            getData()
        }
    }, [userAccount, web3])
    // update data after succes claiming
    useEffect(() => {
        if (userAccount && web3) {
            getData()
        }
    }, [alertInfo])
   
    // claim function
    const claim = async () => {
        //check()
        try {
            if (!claimable) {
                setLoading(!loading)
                if(!userAccount){
                    dispatch(
                        changeAlertData({
                            type: 'Alert',
                            active: true,
                            txhash: "",
                            content:"You need connect wallet on Binance Smart Chain to claim."
                        })
                    )
                }
                else{
                    dispatch(
                        changeAlertData({
                            type: 'Error',
                            active: true,
                            txhash: "",
                            content: "You don't have something to claim"
                        })
                    )
                }
                setLoading((loading) => loading = !loading)
                return 0;
            }
            if(contractBalance < claimable){
                dispatch(
                    changeAlertData({
                        type: 'Error',
                        active: true,
                        txhash: "",
                        content:"Not enough balance in smart contract. Please contact project owner."
                    })
                )
                return;
            }
            let transactionHash;
            if (userAccount) {
                setLoading(!loading)
                await contract?.methods.claim().send({ from: userAccount })
                    .once("transactionHash", (hash) => {
                        transactionHash = hash
                    })
                dispatch(
                    changeAlertData({
                        type: 'Success',
                        active: true,
                        txhash: transactionHash,
                        content:"View on Explorer"
                    })
                )
                setLoading((loading) => loading = !loading)
            } else {
                setLoading(!loading)
                dispatch(
                    changeAlertData({
                        type: 'Alert',
                        active: true,
                        txhash: "",
                        content:"You need connect wallet on Binance Smart Chain to claim."
                    })
                )
                setLoading((loading) => loading = !loading)
            }

        } catch (err) {
            setLoading(!loading)
            console.log(err)
            setLoading((loading) => loading = !loading)
            dispatch(
                changeAlertData({
                    type: 'Error',
                    active: true,
                    txhash: "",
                    content:"Claim token fails!"
                })
            )

        }
    }
    return (

        <div className="container">
            <Loading className={loading ? 'loading active' : 'loading'} />
            <Alert />
            <div className="connected-page">
                <h2 className="black">
                    {label ? label : ''}
                </h2>
                <div className="connected-page_release">
                    <span className="release-title">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule='evenodd' clipRule="evenodd" d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10ZM12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6Z" fill="white" />
                        </svg>
                        Release:
                    </span>
                    <p>
                        All linear from
                        <span className="release-start_date"> {startTime ? startTime : ''} </span>
                        to
                        <span className="release-end_date"> {endTime ? endTime : ''} </span>
                        every
                        <span className="release-time"> {seconds ? seconds : ''} </span>
                    </p>
                </div>
                <div className="connected-page_content">
                    <div className="content-data">
                        <div className="content-data_card">
                            <h4>
                                Allocated <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.64">
                                        <path fillRule='evenodd' clipRule="evenodd" d="M8.91694 2.43004C9.13783 2.93623 8.90655 3.52564 8.40036 3.74653C7.05296 4.33452 5.8701 5.24362 4.9552 6.39436C4.04031 7.5451 3.42123 8.90243 3.1521 10.3477C2.88297 11.793 2.97197 13.2822 3.41133 14.6851C3.8507 16.088 4.62704 17.3619 5.67248 18.3955C6.71793 19.4291 8.00065 20.1908 9.40849 20.6141C10.8163 21.0375 12.3065 21.1095 13.7486 20.8238C15.1907 20.5382 16.5408 19.9037 17.6811 18.9757C18.8213 18.0477 19.7168 16.8546 20.2894 15.5005C20.5045 14.9919 21.0912 14.7539 21.5999 14.969C22.1085 15.1841 22.3465 15.7708 22.1314 16.2795C21.4316 17.9344 20.3371 19.3927 18.9435 20.5269C17.5499 21.6611 15.8997 22.4366 14.1371 22.7857C12.3746 23.1348 10.5533 23.0468 8.8326 22.5294C7.1119 22.0121 5.54413 21.081 4.26637 19.8178C2.9886 18.5545 2.03974 16.9975 1.50274 15.2828C0.965745 13.5681 0.856961 11.748 1.1859 9.98156C1.51484 8.21513 2.27149 6.55616 3.38969 5.1497C4.5079 3.74324 5.95361 2.63212 7.60044 1.91347C8.10663 1.69258 8.69604 1.92385 8.91694 2.43004Z" fill="black" />
                                        <path fillRule='evenodd' clipRule="evenodd" d="M11.2929 1.29289C11.4804 1.10536 11.7348 1 12 1C13.4445 1 14.8749 1.28452 16.2095 1.83733C17.5441 2.39013 18.7567 3.20038 19.7782 4.22183C20.7996 5.24327 21.6099 6.4559 22.1627 7.79048C22.7155 9.12506 23 10.5555 23 12C23 12.5523 22.5523 13 22 13H12C11.4477 13 11 12.5523 11 12V2C11 1.73478 11.1054 1.48043 11.2929 1.29289ZM13 3.05573V11H20.9443C20.8505 10.1614 20.6391 9.33849 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.6615 3.3609 13.8386 3.14949 13 3.05573Z" fill="black" />
                                    </g>
                                </svg>

                            </h4>
                            <h2 className='data-counts'>{allocated ? allocated : data_text} {symbol ? symbol : ''}</h2>
                        </div>
                        <div className="content-data_card">
                            <h4>
                                Unlocked Till Now <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.64">
                                        <path d="M19 23H5C3.3 23 2 21.7 2 20V13C2 11.3 3.3 10 5 10H19C20.7 10 22 11.3 22 13V20C22 21.7 20.7 23 19 23ZM5 12C4.4 12 4 12.4 4 13V20C4 20.6 4.4 21 5 21H19C19.6 21 20 20.6 20 20V13C20 12.4 19.6 12 19 12H5Z" fill="black" />
                                        <path d="M7 12C6.4 12 6 11.6 6 11V7C6 5.4 6.6 3.9 7.8 2.8C8.9 1.6 10.4 1 12 1C14.8 1 17.3 3 17.9 5.8C18 6.3 17.6 6.9 17.1 7C16.6 7.1 16 6.8 15.9 6.2C15.5 4.3 13.9 3 12 3C10.9 3 9.9 3.4 9.2 4.2C8.4 4.9 8 5.9 8 7V11C8 11.6 7.6 12 7 12Z" fill="black" />
                                    </g>
                                </svg>

                            </h4>
                            <h2 className='data-counts'>{unlocked ? unlocked : data_text} {symbol ? symbol : ''}</h2>
                        </div>
                        <div className="content-data_card">
                            <h4>
                                Claimed already <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.64">
                                        <path fillRule='evenodd' clipRule="evenodd" d="M15.663 3.77343C13.8902 2.98353 11.9096 2.78785 10.0166 3.21556C8.12351 3.64327 6.41942 4.67147 5.15845 6.1468C3.89749 7.62212 3.14721 9.46554 3.01951 11.4021C2.89181 13.3387 3.39354 15.2647 4.44987 16.8928C5.50619 18.5209 7.06051 19.764 8.88102 20.4366C10.7015 21.1091 12.6907 21.1752 14.5518 20.6249C16.413 20.0747 18.0464 18.9375 19.2084 17.3831C20.3705 15.8286 20.9989 13.9402 21 11.9994V11.08C21 10.5277 21.4477 10.08 22 10.08C22.5523 10.08 23 10.5277 23 11.08V12C22.9986 14.3721 22.2306 16.6807 20.8103 18.5806C19.39 20.4804 17.3936 21.8703 15.1189 22.5429C12.8442 23.2154 10.413 23.1347 8.18792 22.3126C5.96285 21.4906 4.06312 19.9713 2.77206 17.9813C1.48099 15.9914 0.86777 13.6374 1.02384 11.2705C1.17992 8.9036 2.09693 6.65053 3.63811 4.84735C5.17929 3.04418 7.26206 1.78749 9.57581 1.26473C11.8896 0.741975 14.3103 0.981145 16.477 1.94657C16.9815 2.17135 17.2082 2.76253 16.9834 3.267C16.7587 3.77148 16.1675 3.99821 15.663 3.77343Z" fill="black" />
                                        <path fillRule='evenodd' clipRule="evenodd" d="M22.7068 3.29254C23.0975 3.68287 23.0978 4.31603 22.7075 4.70675L12.7075 14.7168C12.52 14.9044 12.2656 15.0099 12.0002 15.01C11.7349 15.0101 11.4805 14.9047 11.2929 14.7171L8.29289 11.7171C7.90237 11.3266 7.90237 10.6934 8.29289 10.3029C8.68342 9.91237 9.31658 9.91237 9.70711 10.3029L11.9996 12.5954L21.2925 3.29325C21.6829 2.90253 22.316 2.90221 22.7068 3.29254Z" fill="black" />
                                    </g>
                                </svg>
                            </h4>
                            <h2 className='data-counts' >{claimed ? claimed : data_text} {symbol ? symbol : ''}</h2>
                        </div>
                        <div className="content-data_card">
                            <h4>
                                Claimable Now <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.64">
                                        <path fillRule='evenodd' clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="black" />
                                        <path fillRule='evenodd' clipRule="evenodd" d="M12 5C12.5523 5 13 5.44772 13 6V11.382L16.4472 13.1056C16.9412 13.3526 17.1414 13.9532 16.8944 14.4472C16.6474 14.9412 16.0468 15.1414 15.5528 14.8944L11.5528 12.8944C11.214 12.725 11 12.3788 11 12V6C11 5.44772 11.4477 5 12 5Z" fill="black" />
                                    </g>
                                </svg>

                            </h4>
                            <h2 className='data-counts'>{claimable ? claimable : data_text} {symbol ? symbol : ''}</h2>
                        </div>
                    </div>
                    <div className="claim-btn" onClick={() => claim()}>
                        <img src={claim_btn} alt="" />
                    </div>
                </div>


                <div className="connect-left_bg">
                    <img src={connect_left_bg} alt="" />
                </div>
                <div className="connect-right_bg">
                    <img src={connect_right_bg} alt="" />
                </div>
            </div>
        </div>
    );
}
export default ConnectedPage
