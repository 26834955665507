import EditBtn from '../assets/images/edit_btn.png'
import { Link } from 'react-router-dom';
const ManageData = () => {

    return(
        <li className='manage-body_body-component'>
                            <ul className='manage-body_content'>
                                <li className='manage-contract_address'>
                                    <span className='manage-contract_address-title'>CONTRACT ADDRESS</span>
                                    <span className='blue'>contract adress type 1</span>
                                </li>
                                <li className='manage-contract_id'>
                                    <span className='manage-contract_id-title'>TOKEN ID</span>
                                    <span>987df023...jd12</span>
                                </li>
                                <li className='manage-actions manage-contract_endDate'>
                                    <span className='manage-contract_endDate-title'>Token Transfer</span>
                                    <span>Lorem Ipsum</span>
                                    <Link to='/manage/987df023...jd12' className='manage-edit_btn'>
                                        <img src={EditBtn} alt="" />
                                    </Link>
                                </li>
                            </ul>
                        </li>
    )
}
export default ManageData;