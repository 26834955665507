import Logo from '../assets/images/logo.png'
import { Link, useLocation } from 'react-router-dom';
import metamaskConnect from '../metamask/metamaskConnect';
import { getWeb3,changeAddress } from '../store/slices/alertSlice';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';

const Header = () => {
    const dispatch = useDispatch()
    const { userAccount} = useSelector(state => state.alert)
    
    const connect = async () => {
        if(!userAccount){
            let metamaskData;
        let userAccount;
        // connect to metamask
       await metamaskConnect()
        // get web3 and user account
        metamaskData = await metamaskConnect()
        userAccount = metamaskData.account
        // send data to redux
        dispatch(
            getWeb3({
                account: userAccount,
                chainId: window.ethereum.networkVersion
            })
        )
        }
    }
    return (
        <header className="container">
            <div className='header'>
                <div className="logo">
                        <Link to='/'><img src={Logo} alt="" /></Link>
                </div>
                <span className="connect-btn" onClick={() => connect()}>
                       {userAccount ?  `${userAccount?.substr(0, 4)}...${userAccount?.substr(-4)}` : 'Connect Wallet'}
                </span>
            </div>

        </header>
    )
}
export default Header;