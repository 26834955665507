import Main_bg from '../assets/images/main_page_bg.png'
import { Link } from 'react-router-dom';

const MainPage = () => {
    
    return (
        <div className="container">
            <div className="main-page">
                <div className="main-page_text">
                    <h2 className="black">
                        Lorem ipsum dolor sit amet
                    </h2>
                    <h1 className="black">
                        Lorem ipsum
                        dolor sit amet
                    </h1>
                    <p className="black">
                        A shaman is a spiritual leader and healer who communicates with the spirit world for guidance, healing, and prophecy. They often use rituals, music, dance, and ceremonies to interact with gods and spirits. Shamans are typically.
                    </p>
                    <Link to='/0xD3042E5CCFf74040d43b4Ba340ccf4f93868568c' className="black">
                        Lorem ipsum
                    </Link>
                </div>
                <div className="main-page_img">
                    <img src={Main_bg} alt="" />
                </div>
            </div>
        </div>
    );
}
export default MainPage;