import './assets/App.css'
import Header from './components/Header';
import { Routes, Route, useLocation } from 'react-router-dom';
import ConnectedPage from './pages/ConnectedPage';
import { useDispatch,useSelector } from 'react-redux';
import { useEffect} from 'react';
import { getWeb3,changeAddress } from './store/slices/alertSlice';
import MainPage from './pages/MainPage';
import Manage from './pages/Manage';
import EditContract from './pages/EditContract';
import AddContract from './pages/AddContract';

function App() {
 
  const dispatch = useDispatch()
  useEffect(() => {
    // checking if user connected to metamask.
    const getAcccount = async () => {
      let account = await window.ethereum.request({ method: 'eth_accounts' });
      account = account[0]
      if (account?.length > 0) {
        dispatch(
          getWeb3({
            account: account,
            chainId: window.ethereum.networkVersion,
          })
        )
      } else {
        dispatch(
          getWeb3({
            account: null,
            chainId: null,
            toggle: true
          })
        )
      }
    }
    getAcccount()

  }, [])
  //update page if user changed account
  useEffect(() => {
    if(window.ethereum){
      window.ethereum.on('accountsChanged', () => {
        window.location.reload(true)
      });
      //update page if user changed network
      window.ethereum.on('networkChanged', () => {
        window.location.reload(true)
      });
    }
  },[])

  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path='/' element={<MainPage />}  />
        <Route path={'/:address' } element={<ConnectedPage />}  />
        <Route path='/manage' element={<Manage/>} />
        <Route path='/manage/:id' element = {<EditContract />} />
        <Route path='/addContract' element= {<AddContract />} />
      </Routes>
    </div>
  );
}

export default App;
