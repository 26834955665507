const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)




//gettin Allocated value
const getAllocated = async (web3,contract,userAccount) => {
    let allocated= await contract?.methods.getAllocation().call({from: userAccount})
    allocated =  web3?.utils.fromWei(allocated,'ether')
    return allocated;
}
// getting Unlocked value
const getUnlocked = async (web3,contract,userAccount) => {
    let unlocked = await contract?.methods.getUnlocked().call({from: userAccount})
    unlocked =  web3?.utils.fromWei(unlocked,'ether')
    return unlocked
}
//get claimable data
const getClaimable = async (web3,contract,userAccount) => {
    let claimable = await contract?.methods.getClaimable().call({from: userAccount})
    claimable = web3?.utils.fromWei(claimable, 'ether')
    return claimable
}
//get claimed data
const getClaimed = async (web3,contract,userAccount) => {
    let claimed =await contract?.methods.getClaimed().call({from: userAccount})
    claimed = web3?.utils.fromWei(claimed,'ether')
    return claimed
}
// get Interval in seconds
const getIntervalInSeconds = async (contract) => {
    let seconds = await contract.methods?.getIntervalInSeconds().call()
    return seconds
}
// get End Time data
const getTimeEnd = async (contract) => {
    let endTime = +(await contract.methods.getEndTime().call())*1000
    let seconds = +(await contract?.methods.getIntervalInSeconds().call())*1000
    endTime=dayjs(endTime - seconds).utc().format('DD-MMM-YYYY HH:mm:ss [UTC]')
    return endTime
}
// get Start Time data
const getTimeStart = async (contract) => {
    let startTime = +(await contract.methods.getStartTime().call())*1000
    startTime = dayjs(startTime).utc().format('DD-MMM-YYYY HH:mm:ss [UTC]')
    return startTime
}
// get label
const getLabel = async (contract) => {
    let label = await contract?.methods.label().call()
    return label
}
const getContractBalance = async (web3,tokenContract,contractAddress) => {
    let balance = await tokenContract?.methods.balanceOf(contractAddress).call()
    balance = web3?.utils.fromWei(balance, 'ether')
    return balance
}
export  {getAllocated,getUnlocked,getClaimable,
        getClaimed,getIntervalInSeconds,getTimeEnd,
        getTimeStart,getLabel, getContractBalance};
