import Web3 from "web3"
const metamaskConnect = async (web3) => {
    let account;
    return await new Promise((resolve, reject) => {
        const connect =  async () => {
            if (window.ethereum) {
                web3 = new Web3(window.ethereum)
                account = await web3.eth.getAccounts()
                try {
                    await window.ethereum.enable();
                    resolve({web3: web3, account: account[0]})
                }
                catch (error) {
                    reject(error);
                }

            } else if (window.web3) {
                web3 = window.web3;
                account = await web3.eth.getAccounts()
                console.log(web3);
                console.log(('Injected,web3 detected'));
                resolve({web3: web3, account: account[0]})
            }
            else {
                web3 = new Web3('https://bsc-testnet.publicnode.com')
                account = await web3.eth.getAccounts()
                console.log(web3);
                resolve({web3: web3, account: account[0]})
            }
        }
        connect()
    })
}

export default metamaskConnect;