import ManageData from '../components/ManageData';
import { Link } from 'react-router-dom';
const Manage = () => {

    return (
        <div className="container">
            <div className="manage">
                <div className="manage-head">
                    <span className="manage-heading black">Manage</span>
                    <Link to='/addContract' className="manage-add black">
                        Add
                    </Link>
                </div>
                <div className="manage-body">
                    <ul className='manage-body_head'>
                        <li><span>CONTRACT ADDRESS</span></li>
                        <li><span>TOKEN ID</span></li>
                        <li><span>Token Transfer</span></li>
                    </ul>

                    <ul className='manage-body_body'>
                        <ManageData />                       
                    </ul>

                  
                </div>
            </div>
        </div>
    )
}

export default Manage;