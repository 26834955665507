
const SetupClaim = () => {

    return (
        <div className="manage-contract_component">
            <h2 className="black manage-contract_heading">
                Set up claim
            </h2>
            <form action="">
                <div className="setup-claim-input">
                    <label htmlFor="contractName">Contract Name</label>
                    <input type="text" id="contractName" name="contractName" placeholder="Enter contract name" />
                </div>

                <div className="setup-claim-input">
                    <label htmlFor="tokenID">Token ID</label>
                    <input type="text" id="tokenID" name="tokenID" placeholder="Enter Token ID" />
                </div>

                <div className="setup-claim-input">
                    <label htmlFor="startTime">Start time</label>
                    <input type="datetime-local" id="startTime" name="startTime" placeholder="dd\mm\yy"  />
                </div>

                <div className="setup-claim-input">
                    <label htmlFor="interval">Interval</label>
                    <input type="text" id="interval" name="interval" placeholder="2000"  />
                </div>

                <div className="setup-claim-input">
                    <label htmlFor="round">Round</label>
                    <input type="text" id="round" name="round" placeholder="3"   />
                </div>

                <div className="setup-claim-input">
                    <label htmlFor="transferToken">Transfer token</label>
                    <input type="text" id="transferToken" name="transferToken" placeholder="200000"  />
                </div>

                <div className="manage-submit_change">
                    <button className="submit-editContract yellow-btn">
                        Update
                    </button>
                </div>
            </form>
        </div>
    )
}
export default SetupClaim;