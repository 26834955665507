
import { changeAlertData,getWeb3 } from "../store/slices/alertSlice";
import { useDispatch,useSelector } from "react-redux";

import metamaskConnect from '../metamask/metamaskConnect';
const Alert = () => {

    const dispatch = useDispatch()
    const {alertInfo,usedAlert} = useSelector(state => state.alert)
    
    const closeAlert = () => {
     dispatch(
        changeAlertData({
            type: alertInfo.type,
            active: false
        })
     )
    }
    const connect = async () => {
        let metamaskData;
        let userAccount;
        // connect to metamask
        await metamaskConnect()
        // get web3 and user account
        metamaskData = await metamaskConnect()
        userAccount = metamaskData.account
        dispatch(
            changeAlertData({
                type: alertInfo.type,
                active: false
            })
         )
         // send userAccount to redux
        dispatch(
            getWeb3({
                account: userAccount
            }))
     }   
    return(
        <div className={alertInfo.active ? "alert active" : "alert"} onClick={() => {
            closeAlert()
        }}>
            <div
                className={alertInfo.active ? "alert-content active" : "alert-content"}
                style={{backgroundColor: usedAlert?.bg_color}}
                onClick={(e) => e.stopPropagation()}
             >
                <h2 className="black">
                    {usedAlert?.title}
                </h2>
                <img src={usedAlert?.img} alt="" />
                {
                    alertInfo?.type == 'Success' ?
                    <a href={'https://testnet.bscscan.com/tx/' + usedAlert?.txhash} target='_blank' className="black"> 
                        {usedAlert?.content} 
                    </a>  :
                    <p className="black">
                        {usedAlert?.content}
                    </p>
                    
                }
                <span className="black" onClick={() => {
                    usedAlert?.btn_text === 'Close' ? closeAlert() : connect()
                }}>
                    {usedAlert?.btn_text}
                </span>
            </div>
        </div>
    );
}

export default Alert