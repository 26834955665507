import { createSlice } from "@reduxjs/toolkit";

import error_img from '../../assets/images/error_img.png'
import success_img from '../../assets/images/success_img.png'
import metamask_alert_img from '../../assets/images/metamask_alert_img.png'

const initialState = {
    alertData : [
        {
            title: 'Error',
            img: error_img,
            content: 'Claim token fails!',
            btn_text: 'Close',
            bg_color: '#D3B4AD',
            txhash:""
        },
        {
            title: 'Success',
            img: success_img,
            content: 'View on Explorer',
            btn_text: 'Close',
            bg_color: '#C3C7C3',
            txhash:""
        },
        {
            title: 'Alert',
            img: metamask_alert_img,
            content: 'You need connect wallet on Binance Smart Chain to claim.',
            btn_text: 'Connect wallet',
            bg_color: '#E0C88C',
            txhash:""
        }
    ],

    alertInfo : {
        active: false,
        type: 'Error'
    },
    usedAlert: {},
    userAccount: null,
    chainId: null,
    contractAddress:null,
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers:{
        changeAlertData : (state, action) => {
            return {
                ...state,
                 alertInfo: {...state.alertInfo,
                            active: action.payload.active,
                            type: action.payload.type
                            },
                usedAlert: {...state.alertData.find((data) => data.title == action.payload.type),
                            txhash: action.payload.txhash,
                            content: action.payload.content
                            }
            }
        },
        getWeb3 : (state,action) => {
            return{
                ...state,  userAccount: action.payload.account,chainId: action.payload.chainId
            }
        },
        changeAddress : (state,action) => {
            return{
                ...state, contractAddress: action.payload
            }
        }
    }
})

export const {changeAlertData, getWeb3,changeAddress} = alertSlice.actions
export default alertSlice.reducer