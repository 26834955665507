
const Loading = (props) => {
    const { className } = props
    return (
        <div className={className}>
            <svg-animate trigger="loop">
                <svg
                    className='loading-content'
                    width='100px'
                    height='100px'
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsanim="http://www.w3.org/2000/anim"
                    anim=""
                    animtransform-origin="50% 50%"
                    animduration="0.5"
                    animease="power1.inOut"
                    style={{ display: "block" }}
                >
                    <g id="418cfc0d">
                        <rect width={56} height={56} />
                        <path
                            id="5f025085"
                            d="M15.4337 16.1583L10.404 13.372L23.6006 6.06257V11.6351L15.4337 16.1583ZM45.4985 13.3178L32.4025 6.06257V11.6351L40.4688 16.104L45.4985 13.3178ZM44.9703 23.3592V32.5202L50 35.3064V20.5729L44.9703 23.3592ZM11.0327 23.4738L6.00307 20.6875V35.3064L11.0327 32.5202V23.4738ZM32.4025 44.3588V49.9314L45.599 42.6219L40.5694 39.8357L32.4025 44.3588ZM10.404 42.6219L23.6006 49.9314V44.3588L15.4337 39.8357L10.404 42.6219ZM49.6668 39.9985C49.2015 40.7705 48.3528 41.2047 47.4852 41.2047C47.0577 41.2047 46.6238 41.1022 46.2278 40.879L35.9358 35.1798L30.5164 38.1832V49.5876C30.5164 50.9205 29.391 52 28.0015 52C26.6121 52 25.4867 50.9205 25.4867 49.5876V38.1832L20.0673 35.1798L9.77531 40.873C9.37923 41.0901 8.94542 41.1986 8.5179 41.1986C7.65028 41.1986 6.80153 40.7644 6.33629 39.9925C5.64471 38.8406 6.05337 37.363 7.2542 36.6996L17.5461 31.0004V24.9996L7.26049 19.2944C6.05966 18.631 5.64471 17.1534 6.34257 16.0015C7.03415 14.8496 8.57448 14.4516 9.77531 15.121L20.0673 20.8202L25.4867 17.8228V6.41236C25.4867 5.07953 26.6121 4 28.0015 4C29.391 4 30.5164 5.07953 30.5164 6.41236V17.8168L35.9358 20.8142L46.2278 15.115C47.4286 14.4516 48.9689 14.8436 49.6605 15.9955C50.3521 17.1474 49.9434 18.625 48.7426 19.2884L38.4506 24.9876V30.9883L48.7426 36.6875C49.9434 37.3509 50.3584 38.8285 49.6605 39.9804L49.6668 39.9985ZM33.421 24.9996L28.0015 21.9962L22.5821 24.9996V31.0004L28.0015 34.0038L33.421 31.0004V24.9996Z"
                            fill="black"
                            anim=""
                            animrotation="0|360"
                            animdelay={0}
                            animduration={5}
                            data-svg-origin="28.000000476837158 28"
                            transform="matrix(0.97217,0.23429,-0.23429,0.97217,7.33936,-5.78088)"
                            style={{
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transformOrigin: "0px 0px"
                            }}
                        />
                    </g>
                </svg>
            </svg-animate>
        </div>
    );
}

export default Loading