import { useState } from "react";
const ManageWallets = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileComponent, setFileComponent] = useState(true)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    return (
        <div className="manage-contract_component">
            <h2 className="black manage-contract_heading">
                Manage wallets
            </h2>

            <h3>Add Wallets</h3>
            <form action="" className="manage-wallet_form">
                <div className="manage-wallet_form-content">
                    <div className="add-wallet_tabs">
                        <span
                            className={fileComponent ? "add-tab manage-button" : "add-tab manage-button unactive"}
                            onClick={() => {
                                if (!fileComponent) setFileComponent(!fileComponent)
                            }}
                        >
                            Upload file
                        </span>
                        <span
                            className={fileComponent ? "add-tab manage-button unactive" : "add-tab manage-button"}
                            onClick={() => {
                                if (fileComponent) setFileComponent(!fileComponent)
                            }}
                        >
                            Enter manually
                        </span>
                    </div>
                    {
                        fileComponent
                            ?
                            <div className="add-wallet_by-file custom-file-input">
                                <input
                                    type="text"
                                    value={selectedFile ? selectedFile.name : ''}
                                    readOnly
                                    placeholder="File. CSV file"
                                />
                                <label htmlFor="file-upload" className="manage-button">Choose File</label>
                                <input
                                    type="file"
                                    id="file-upload"
                                    onChange={handleFileChange}
                                />
                            </div>
                            :
                            <div className="add-wallet_manually">
                                <label htmlFor="walletAddress">Wallet adress and Amount</label>
                                <textarea name="walletAddress" id="walletAddress" ></textarea>
                            </div>
                    }
                </div>

                <div className="manage-submit_change">
                    <button className="submit-editContract yellow-btn">
                        Update
                    </button>
                </div>
            </form>

        </div>
    )
}
export default ManageWallets;